<template>
  <v-container fluid class="fill-height" v-bind:style="backgroundDefault">
    <v-row justify="center" align="center" class="d-flex flex-column">
      <div>
        <v-alert type="warning" elevation="12" class="ma-4">
          <h3>{{ this.$root.redeemConfirmationMessage }}</h3>
        </v-alert>
      </div>

      <div>
        <v-btn
          color="primary"
          :rounded="
            $appConfig.hasOwnProperty('button')
              ? $appConfig.button.rounded
              : false
          "
          @click="$router.push('home')"
          min-width="110px"
        >
          {{ $t("common.home") }}
        </v-btn>
      </div>
    </v-row>

    <FlashMessage :position="'right top'"></FlashMessage>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
      clientMessage: null,
    };
  },
  mounted() {
    this.CheckState();
    //this.$appInsights.trackPageView({ name: "RedeemConfirmWarning" });
  },
  methods: {
    async CheckState() {
      if (this.$store.state.code == "") {
        this.$router.push("home");
      }
    },
  },
  computed: {
    backgroundDefault: function() {
      return {
        "background-image":
          "url(" + this.$appConfig.confirmRedemptionBackGroundImage + " )",
        "background-position": "center",
        "background-size": "cover",
      };
    },
  },
};
</script>
